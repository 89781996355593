import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";
import * as yup from "yup";
import NavButton from "../../../components/Buttons/NavButton";
import Loader from "../../../components/Loader/Loader";
import {
    selectStep1Data,
    selectTransactionId,
    setStep1,
} from "../../../features/transactionSlice";
import Step1Service from "../../../services/Step1Service";
import Step2Service from "../../../services/Step2Service";
import RentInput, { ErrorContainer } from "../components/RentInput";
import RentLayout from "../components/RentLayout";
import RentSubtitle from "../components/RentSubtitle";
import Person from "../../../components/Icons/components/Person";
import Businessman from "../../../components/Icons/components/Businessman";
import Building from "../../../components/Icons/components/Building";
import Tile from "../../../components/Icons/components/Tile";
import FlexBox from "../../../components/FlexBox/FlexBox";
import { theme } from '../../../theme'
import SelectPersonalDataProvider from "../../mObywatelIntegration/SelectPersonalDataProvider";
import { customerTypes } from "../../../utils/dictionary";
import { useLocation } from "react-router-dom";
import AuthologicForm from "../../mObywatelIntegration/AuthologicForm";
import { LABELS, NIP_ERROR } from "./ContractData";
import useAuthologic from "../../mObywatelIntegration/useAuthologic";

const SubtitleContainer = styled.div`
    margin-bottom: 28px;
    font-size: 1.75rem;
    text-align: center;
`;
const FormContainer = styled.div`
    margin-top: 30px;
    max-width: 600px;
    margin: auto;
`;

const IconLabel = styled.label`
    display: inline-block;
    cursor: pointer;
    & > input {
        position: absolute;
        display: block;
        width: 0px;
        height: 0px;
        opacity: 0;
        top: -1000px;
        left: -1000px;
    }
`;
const StreetRow = styled.div`
    display: flex;
    gap: 16px;
    & > div:first-child {
        flex: 1;
    }
`;
const CityRow = styled.div`
    display: flex;
    gap: 16px;
    & > div:last-child {
        flex: 1;
    }
`;

const RadioContainer = styled.div`
    border: 1px solid
        ${({ theme, isError }) => (isError ? theme.danger : "transparent")};
    padding: 10px;
    margin: 10px;
    display: flex;
    label > span {
        font-weight: bold;
    }
`;

const InputGroup = styled.div`
    font-size: 1.125rem;
    font-weight: bold;
`;


const Tip = styled.div`
    color: #444;
    margin-top: -10px;
    margin-bottom: 20px;
`


function useQueryParam() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const DesktopContractData = ({ navProps }) => {

    const StandardFormSchema = yup.object().shape({
        apartment: yup.string(),
        city: yup.string().required("Pole wymagane"),
        customerType: yup
            .string()
            .oneOf(["NATURAL_PERSON", "INDIVIDUAL_ENTREPRENEUR", "LEGAL_PERSON"])
            .required("Pole wymagane"),
        firstName: yup.string().required("Pole wymagane"),
        idCard: yup.string().required("Pole wymagane"),
        lastName: yup.string().required("Pole wymagane"),
        personalNumber: yup.string().required("Pole wymagane"),
        phone: yup.string().required("Pole wymagane"),
        postCode: yup.string().required("Pole wymagane"),
        street: yup.string().required("Pole wymagane"),
        // nip: yup
        //     .string(NIP_ERROR)
        //     .min(10, NIP_ERROR)
        //     .max(10, NIP_ERROR)
        //     .when("customerType", {
        //         is: "INDIVIDUAL_ENTREPRENEUR",
        //         then: yup.string().required("Pole wymagane"),
        //     })
        //     .when("customerType", {
        //         is: "LEGAL_PERSON",
        //         then: yup.string(NIP_ERROR).required("Pole wymagane"),
        //     })
        //     .when("customerType", {
        //         is: "NATURAL_PERSON",
        //         then: yup.string(NIP_ERROR),
        //     }),
        nip: yup
            .string("string")
            .test("nip", NIP_ERROR, (value, values) => {
                if (values.parent.customerType === "NATURAL_PERSON") {
                    return true;
                } else {
                    if (value.length === 10) {
                        return true;
                    }
                }
                return false;
            }),
    });
    const mObywatelSchema = yup.object().shape({
        apartment: yup.string(),
        city: yup.string().required("Pole wymagane"),
        customerType: yup
            .string()
            .oneOf(["NATURAL_PERSON", "INDIVIDUAL_ENTREPRENEUR", "LEGAL_PERSON"])
            .required("Pole wymagane"),
        phone: yup.string().required("Pole wymagane"),
        postCode: yup.string().required("Pole wymagane"),
        street: yup.string().required("Pole wymagane"),
        // nip: yup
        //     .string(NIP_ERROR)
        //     .min(10, NIP_ERROR)
        //     .max(10, NIP_ERROR)
        //     .when("customerType", {
        //         is: "INDIVIDUAL_ENTREPRENEUR",
        //         then: yup.string().required("Pole wymagane"),
        //     })
        //     .when("customerType", {
        //         is: "LEGAL_PERSON",
        //         then: yup.string(NIP_ERROR).required("Pole wymagane"),
        //     })
        //     .when("customerType", {
        //         is: "NATURAL_PERSON",
        //         then: yup.string(NIP_ERROR),
        //     }),
        nip: yup
            .string("string")
            .test("nip", NIP_ERROR, (value, values) => {
                if (values.parent.customerType === "NATURAL_PERSON") {
                    return true;
                } else {
                    if (value.length === 10) {
                        return true;
                    }
                }
                return false;
            }),
    });

    const history = useHistory();
    const [step, setStep] = useState(0);
    const [isSubmit, setIsSubmit] = useState(false)
    const dispatch = useDispatch();
    const initialData = useSelector(selectStep1Data);
    const transactionId = useSelector(selectTransactionId);
    const conversation = useQueryParam().get('conversation')
    const authologic = useAuthologic({
        startFetchingData: !!conversation,
        fetchingData: {
            isError: () => {
                setStep(2)
            },
            isFetching: () => {
                setStep(3)
            },
            isSuccess: () => {
                setStep(3)
            }
        }
    });


    const {
        register,
        handleSubmit,
        watch,
        setError,
        clearErrors,
        setValue,
        getValues,
        trigger,
        formState: { errors },
    } = useForm({
        defaultValues: initialData,
        resolver: yupResolver(authologic.status ? mObywatelSchema : StandardFormSchema),
        reValidateMode: 'onChange'
    });

    const { mutateAsync, isLoading } = useMutation(
        Step1Service.saveStep1(transactionId),
        {
            onError: (e) =>     {
                e.response.data?.errors?.forEach(
                    ({ message, invalid_property }) => {
                        setError(invalid_property, {
                            type: "manual",
                            message,
                        });
                    }
                );
                toast.error("Wpisano błędne dane");
                if(authologic.status === 'active') {
                    setStep(3)
                }
            },
        }
    );

    const customerType = watch("customerType");


    useEffect(() => {
        // save cystomer type to store
        dispatch(setStep1({ customerType: customerType }));
        if (customerType === "NATURAL_PERSON") {
            setValue("nip", "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerType]);
    const nip = watch("nip");

    const {
        mutateAsync: checkNipMutation,
        data: nipData,
        isLoading: isNipLoading,
    } = useMutation(Step2Service.checkNip(nip), {
        onError: () => {
            setError("nip", {
                type: "manual",
                message: "Nie znaleziono numeru nip",
            });
        },
        onSuccess: () => {
            clearErrors(["c5", "nip"]);
        }
    });

    const {
        mutateAsync: saveLegalPerson,
        isLoading: isLegalPersonLoading,
    } = useMutation(Step2Service.saveLegalPerson(transactionId), {
        onError: (e) =>     {
            e.response.data?.errors?.forEach(
                ({ message, invalid_property }) => {
                    setError(invalid_property, {
                        type: "manual",
                        message,
                    });
                }
            );
            toast.error("Wpisano błędne dane");
            if(authologic.status === 'active') {
                setStep(3)
            }
        },
    });

    const {
        mutateAsync: saveIndividualEntrepreneur,
        isLoading: isIndividualEntrepreneuerLoading,
    } = useMutation(Step2Service.saveIndividualEntrepreneur(transactionId), {
        onError: (e) =>     {
            e.response.data?.errors?.forEach(
                ({ message, invalid_property }) => {
                    setError(invalid_property, {
                        type: "manual",
                        message,
                    });
                }
            );
            toast.error("Wpisano błędne dane");
            if(authologic.status === 'active') {
                setStep(3)
            }
        },
    });

    useEffect(() => {
        if (nip?.length === 10) {
            checkNipMutation();
        }
    }, [checkNipMutation, nip]);

    const matchFields = (data) => {
        const {
            apartment,
            city,
            customerType,
            firstName,
            idCard,
            lastName,
            personalNumber,
            phone,
            postCode,
            street,
        } = data;

        return {
            c2: firstName,
            c3: lastName,
            c4: personalNumber,
            c8: idCard,
            c17: phone,
            c10: street,
            c11: city,
            c12: postCode,
            c36: apartment,
            // c29: 1,
            c27: { c2: customerType },
            // c39: 0,
            // c45: 0,
        };
    }

    const onSubmit = async (values) => {
        const fields = matchFields(values);

        if (customerType === "NATURAL_PERSON") {
            await mutateAsync(fields);
            dispatch(setStep1(values));
            history.push("/rent/financial-data");
        }
        if (customerType === "INDIVIDUAL_ENTREPRENEUR") {
            await mutateAsync(fields);
            await saveIndividualEntrepreneur({
                c5: nip,
            });
            dispatch(setStep1(values));
            // history.push("/rent/financial-data");
            history.push("/rent/online-application");
        }
        if (customerType === "LEGAL_PERSON") {
            await mutateAsync(fields);
            await saveLegalPerson({
                c5: nip,
            });
            history.push("/rent/online-application");
            dispatch(setStep1(values));
        }
    };

    const validateData = (param) => {
        if (customer !== 'NATURAL_PERSON') {
            trigger(['nip', 'c20'])
        } else {
            clearErrors(['nip', 'c20'])
        }

        const data = matchFields(getValues());
        const dataPassedValidation = mutateAsync({...data, validation: param}).then((data) => {
            return step === 2 ? data.data === 'no errors' : (data.data === 'no errors' && Object.keys(errors).length === 0)

        });
        if (customer === 'INDIVIDUAL_ENTREPRENEUR' && nip.length >= 0) {
            const nipPassedValidation = saveIndividualEntrepreneur({
                c5: nip,
                validation: true
            }).then((data) => {
                return step === 2 ? data.data === 'no errors' : (data.data === 'no errors' && Object.keys(errors).length === 0)
            });
            return dataPassedValidation && nipPassedValidation;
        } else if (customer === 'LEGAL_PERSON' && nip.length > 0) {
            const nipPassedValidation = saveLegalPerson({
                c5: nip,
                validation: true
            }).then((data) => {
                return step === 2 ? data.data === 'no errors' : (data.data === 'no errors' && Object.keys(errors).length === 0)
            });
            return dataPassedValidation && nipPassedValidation;
        } else {
            dataPassedValidation.then((data) => {
            })
            return dataPassedValidation
        }
    }

    const showLoader =
        isLoading || isLegalPersonLoading || isIndividualEntrepreneuerLoading;

    const [customer, setCustomer] = useState(customerType || 'NATURAL_PERSON')
    const handleClick = (e) => {
        setCustomer(e.target.value)
    }

    /*
        const checkErrors = () => {
            if (!authologic.status &&
                (errors['firstName']
                    || errors['lastName']
                    || errors['phone']
                    || errors['personalNumber']
                    || errors['idCard']
                    || errors['nip'])
            ) {
                setStep(2)
            }
        }

        useEffect(() => {
            checkErrors()
            // eslint-disable-next-line
        }, [errors])*/



    useEffect(() => {
        if (step === 3) {
            setIsSubmit(true)
        }
        else {
            setIsSubmit(false)
        }
    }, [step])

    return (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <RentLayout {...navProps} showNav={false} showLoader={showLoader}>
                {authologic.isFetching && <Loader fixed={true} />}
                {step === 0 && (
                    <div>
                        <SubtitleContainer>
                            <RentSubtitle>
                                KTO WYNAJMUJE SPRZĘT?
                            </RentSubtitle>
                        </SubtitleContainer>
                        <RadioContainer isError={!!errors?.customerType}>
                            <FlexBox justifyContent='space-between' flex="1">
                                <InputGroup>
                                    <IconLabel htmlFor="NATURAL_PERSON">
                                        <Tile
                                            icon={<Person color={theme.primary} />}
                                            active={customer === 'NATURAL_PERSON'}
                                        />
                                        <input
                                            type='radio'
                                            id='NATURAL_PERSON'
                                            value='NATURAL_PERSON'
                                            {...register("customerType")}
                                            onClick={handleClick}
                                        />
                                    </IconLabel>
                                    <p>Osoba Fizyczna</p>
                                </InputGroup>
                                <InputGroup>
                                    <IconLabel htmlFor="INDIVIDUAL_ENTREPRENEUR"  >
                                        <Tile
                                            icon={<Businessman color={theme.primary} />}
                                            active={customer === 'INDIVIDUAL_ENTREPRENEUR'}
                                        />
                                        <input
                                            type='radio'
                                            id='INDIVIDUAL_ENTREPRENEUR'
                                            value='INDIVIDUAL_ENTREPRENEUR'
                                            {...register("customerType")}
                                            onClick={handleClick}
                                        />
                                    </IconLabel>
                                    <p>Jednoosobowa działalność gospodarcza</p>
                                </InputGroup>
                                <InputGroup>
                                    <IconLabel htmlFor="LEGAL_PERSON">
                                        <Tile
                                            icon={<Building color={theme.primary} />}
                                            active={customer === 'LEGAL_PERSON'}
                                        />
                                        <input
                                            type='radio'
                                            id='LEGAL_PERSON'
                                            value='LEGAL_PERSON'
                                            {...register("customerType")}
                                            onClick={handleClick}
                                        />
                                    </IconLabel>
                                    <p>Spółka / Fundacja / Instytucja</p>
                                </InputGroup>
                            </FlexBox>
                            <ErrorContainer>
                                {errors?.customerType?.message}
                            </ErrorContainer>
                        </RadioContainer>
                    </div>
                )}
                {step === 1 && (
                    <div>
                        <SubtitleContainer>
                            <RentSubtitle>{customerTypes[customer]}</RentSubtitle>
                        </SubtitleContainer>
                        <SelectPersonalDataProvider />
                    </div>
                )}
                {step === 2 && (
                    <FormContainer>
                        <SubtitleContainer>
                            <RentSubtitle>DANE WNIOSKODAWCY:</RentSubtitle>
                        </SubtitleContainer>
                        {(customerType === "INDIVIDUAL_ENTREPRENEUR" ||
                            customerType === "LEGAL_PERSON") && (
                                <>
                                    <RentInput
                                        label={LABELS.nip[customerType]}
                                        name="nip"
                                        error={errors["c5"] || errors.nip}
                                        register={register}
                                    />
                                    {isNipLoading && <Loader />}
                                    {nipData?.c20 && (
                                        <RentInput
                                            label={LABELS.companyName[customerType]}
                                            value={nipData?.c20}
                                            disabled
                                        />
                                    )}
                                </>
                            )}
                        <RentInput
                            label={
                                LABELS.firstName[
                                customerType || "INDIVIDUAL_ENTREPRENEUR"
                                ]
                            }
                            register={register}
                            name="firstName"
                            error={errors["c2"] || errors.firstName}
                            onChange={(e) => {
                                clearErrors(['firstName', 'c2'])
                                setValue('firstName', e.target.value);
                            }
                        }
                        />

                        <RentInput
                            label={
                                LABELS.lastName[
                                customerType || "INDIVIDUAL_ENTREPRENEUR"
                                ]
                            }
                            register={register}
                            name="lastName"
                            error={errors["c3"] || errors.lastName}
                            description={'Nazwisko dwuczłonowe wpisz z myślnikiem, czyli np. “Kowalska-Nowak”'}
                            onChange={(e) => {
                                clearErrors(['lastName', 'c3'])
                                setValue('lastName', e.target.value);
                            }}

                        />
                        <Tip>Nazwisko dwuczłonowe wpisz z myślnikiem, czyli np. “Kowalska-Nowak”</Tip>


                        <RentInput
                            label={
                                LABELS.phone[
                                customerType || "INDIVIDUAL_ENTREPRENEUR"
                                ]
                            }
                            description="Numer telefonu będzie potrzebny w trakcie realizacji transakcji do potwierdzenia warunków umowy oraz przy odbiorze sprzętu."
                            register={register}
                            name="phone"
                            error={errors["c17"] || errors.phone}
                            onChange={(e) => {
                                clearErrors(['phone', 'c17'])
                                setValue('phone', e.target.value);
                            }}
                        />
                        <RentInput
                            label={
                                LABELS.personalNumber[
                                customerType || "INDIVIDUAL_ENTREPRENEUR"
                                ]
                            }
                            description="Numer PESEL jest nam niezbędny do weryfikacji tożsamości Klienta wypożyczającego sprzęt."
                            register={register}
                            name="personalNumber"
                            error={errors["c4"] || errors.personalNumber}
                            onChange={(e) => {
                                clearErrors(['personalNumber', 'c4'])
                                setValue('personalNumber', e.target.value);
                            }}
                        />
                        <RentInput
                            label={
                                LABELS.idCard[
                                customerType || "INDIVIDUAL_ENTREPRENEUR"
                                ]
                            }
                            description="Numer dowodu osobistego jest niezbędny do identyfikacji Najemcy przy odbiorze sprzętu."
                            register={register}
                            name="idCard"
                            error={errors["c8"] || errors.idCard}
                            onChange={(e) => {
                                clearErrors(['idCard', 'c8'])
                                setValue('idCard', e.target.value);
                            }}
                        />
                    </FormContainer>
                )}
                {step === 3 && (
                    <FormContainer>
                        {authologic.isSuccess && <AuthologicForm nipChecker={{data: nipData, isLoading: isNipLoading}} customerType={customerType} errors={errors} register={register} labels={LABELS} />}
                        <SubtitleContainer>
                            <RentSubtitle>
                                {
                                    `${LABELS.address[customerType || "INDIVIDUAL_ENTREPRENEUR"]}:`
                                }
                            </RentSubtitle>
                        </SubtitleContainer>
                        <StreetRow>
                            <RentInput
                                label="Ulica i numer budynku"
                                register={register}
                                name="street"
                                error={errors["c10"] || errors.street}
                                onChange={(e) => {
                                    clearErrors(['street', 'c10'])
                                    setValue('street', e.target.value);
                                }}
                            />
                            <RentInput
                                label="Numer lokalu"
                                register={register}
                                name="apartment"
                                error={errors["c36"] || errors.apartment}
                                onChange={(e) => {
                                    clearErrors(['apartment', 'c36'])
                                    setValue('apartment', e.target.value);
                                }}
                            />
                        </StreetRow>
                        <CityRow>
                            <RentInput
                                label="Kod pocztowy"
                                register={register}
                                name="postCode"
                                error={errors["c12"] || errors.postCode}
                                onChange={(e) => {
                                    clearErrors(['postCode', 'c12'])
                                    setValue('postCode', e.target.value);
                                }}
                            />
                            <RentInput
                                label="Miejscowość"
                                register={register}
                                name="city"
                                error={errors["c11"] || errors.city}
                                onChange={(e) => {
                                    clearErrors(['city', 'c11'])
                                    setValue('city', e.target.value);
                                }}
                            />
                        </CityRow>
                    </FormContainer>
                )}
                <FormContainer>
                    <NavButton
                        backText='Twój koszyk najmu '
                        backLink={step === 0 && "/basket"}
                        onBackClicked={() => {
                            // if(authologic.status === 'inactive' && errors && step === 2) {
                            if(step === 2) {
                                setStep(0)
                            } else {
                                setStep((step) => Math.max(step - 1, 0))
                            }
                        }}
                        onNextClicked={() => {
                            // DISABLE AUTHOLOGIC
                            /*                            if (authologic.status === 'active' && step === 1) {
                                authologic.start()
                            } else if(authologic.status === 'inactive' && errors && step === 0) {
                                setStep(2)*/
                            if (step === 0) {
                                setStep(2)
                            } else if(authologic.status === 'inactive' && step === 2) {
                                validateData('form_step1_personal_data').then((result) => {
                                    console.log(result)
                                    if(result) {
                                        setStep(3)
                                    }
                                })
                            } else {
                                setStep((step) => Math.min(step + 1, 3))
                            }
                        }}
                        submit={isSubmit}
                        disabled={showLoader}
                        hideBackButton={authologic.status === 'active' && authologic.isSuccess}
                    />

                </FormContainer>
            </RentLayout>
        </form >
    );
};

export default DesktopContractData;

